import { SignedIn, SignedOut, UserButton } from '@clerk/remix';
import { If } from '@naamdeo/react-lib/control-flow/If';
import { Button } from '@naamdeo/shadcn-ui/components/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@naamdeo/shadcn-ui/components/dropdown-menu';
import { ScrollArea } from '@naamdeo/shadcn-ui/components/scroll-area';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@naamdeo/shadcn-ui/components/sheet';
import { Typography } from '@naamdeo/shadcn-ui/components/typography';
import { cn } from '@naamdeo/shadcn-ui/lib/utils';
import { Link, useLocation, useRouteLoaderData } from '@remix-run/react';
import { ArrowLeft, BookOpen, CircleCheckIcon, CircleIcon, MenuIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CartSheet } from '~/components/cart-sheet';
import { type loader as lessonRouteLoader } from '~/routes/course.$courseSlug_.learn.$lessonSlug';
import { getCourseDurationDisplay } from '~/utils/display';
import { useParentPath } from '~/utils/remix-hooks';

interface HeaderProps {
  className?: string;
}

export function Header({ className }: HeaderProps) {
  const location = useLocation();
  const isLessonPage = useMemo(() => /^\/course\/[^/]+\/learn\/[^/]+$/.test(location.pathname), [location.pathname]);
  return (
    <>
      <If condition={isLessonPage}>
        <LessonPageHeader className={className} />
      </If>
      <If condition={!isLessonPage}>
        <DefaultHeader className={className} />
      </If>
    </>
  );
}

function DefaultHeader({ className }: HeaderProps) {
  return (
    <header className={cn('fixed left-0 right-0 top-0 z-40 bg-[#23292E]', className)}>
      <nav className="flex h-full items-center justify-between px-4 lg:px-8 xl:px-32">
        <div>
          <Link className="text-2xl font-bold text-gray-800 dark:text-white" to="/">
            <img
              className="h-10"
              src="https://public-assets.naamdeo.org/cdn-cgi/image/width=250,quality=90,format=auto/images/logo-new-light.png"
              alt="Naamdeo"
            />
          </Link>
        </div>
        <NavLinks />
        <MobileNavLinks />
      </nav>
    </header>
  );
}

function LessonPageHeader({ className }: HeaderProps) {
  const routeData = useRouteLoaderData<typeof lessonRouteLoader>('routes/course.$courseSlug_.learn.$lessonSlug');
  const parentPath = useParentPath();

  const location = useLocation();
  const [currentPathname, setCurrentPathname] = useState(location.pathname);
  const [open, setOpen] = useState(false);
  const onOpenChange = useCallback((newOpen: boolean) => setOpen(newOpen), []);

  useEffect(() => {
    if (currentPathname !== location.pathname) {
      setCurrentPathname(location.pathname);
      setOpen(false);
    }
  }, [location.pathname, currentPathname]);

  if (!routeData) return null;
  const allLessons = routeData.courseInfo.modules.flatMap((m) => m.lessons);

  return (
    <>
      <div
        className={cn(
          'fixed left-0 top-0 z-40 w-full border-b border-gray-200 bg-background px-4 py-4 lg:px-8 xl:px-32',
          className
        )}
      >
        <div className="flex justify-between">
          <div className="flex items-center gap-x-1">
            <ArrowLeft size={20} strokeWidth={1.25} />
            <Link to={`/course/${routeData.courseSlug}`}>Back to Course</Link>
          </div>
          <h4 className="hidden text-2xl lg:block">
            <span className="font-heading text-ternary">{routeData.courseInfo.name}</span>
          </h4>
          <Sheet open={open} onOpenChange={onOpenChange}>
            <SheetTrigger>
              <div className="flex cursor-pointer items-center gap-x-2">
                <BookOpen size={20} strokeWidth={1.25} />
                <p>Chapters</p>
              </div>
            </SheetTrigger>
            <SheetContent className="w-full">
              <SheetHeader className="h-full">
                <SheetTitle>Chapters</SheetTitle>
                <ScrollArea className="h-full">
                  <div className="flex flex-col gap-4">
                    {allLessons.map((lesson) => (
                      <div key={lesson.id}>
                        <Link to={`${parentPath}/${lesson.slug}`}>
                          <div
                            className={cn(
                              'flex items-center gap-x-2 rounded-lg bg-accent p-4 text-left text-accent-foreground',
                              {
                                'bg-primary text-primary-foreground': lesson.slug === routeData.lessonSlug,
                              }
                            )}
                          >
                            <div>
                              <If condition={routeData.allLessonsProgress?.lessonProgress[lesson.slug]?.completedAt}>
                                <CircleCheckIcon size={30} className="rounded-full text-inherit" />
                              </If>
                              <If condition={!routeData.allLessonsProgress?.lessonProgress[lesson.slug]?.completedAt}>
                                <CircleIcon size={30} className="rounded-full text-inherit" />
                              </If>
                            </div>
                            <div>
                              <SheetDescription asChild className="text-inherit">
                                <Typography className="">
                                  Chapter {lesson.order}: {lesson.name}
                                </Typography>
                              </SheetDescription>
                              <Typography variant="smallText" className="text-xs text-inherit">
                                {getCourseDurationDisplay(lesson.duration)}
                              </Typography>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </ScrollArea>
              </SheetHeader>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </>
  );
}

function NavLinks() {
  return (
    <nav className="hidden items-center justify-center space-x-4 lg:flex">
      <SignedOut>
        <Link to="/sign-in">
          <Button variant="outline">Log In</Button>
        </Link>
        <Link to="/sign-up">
          <Button>Sign Up</Button>
        </Link>
      </SignedOut>
      <SignedIn>
        <CartSheet />
        <Button variant="link" asChild className="text-primary-foreground">
          <Link to="/my-courses">My Courses</Link>
        </Button>
        <UserButton />
      </SignedIn>
    </nav>
  );
}

function MobileNavLinks() {
  return (
    <nav className="space-x-4 lg:hidden">
      <CartSheet />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="px-0 hover:bg-[#23292E]">
            <MenuIcon className="text-primary-foreground" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <SignedOut>
            <Link to="/sign-in" className="font-serif font-light">
              <DropdownMenuItem className="cursor-pointer text-base">Log In</DropdownMenuItem>
            </Link>
            <DropdownMenuSeparator />
            <Link to="/sign-up" className="font-serif font-light">
              <DropdownMenuItem className="cursor-pointer text-base">Sign Up</DropdownMenuItem>
            </Link>
          </SignedOut>
          <SignedIn>
            <Link to="/my-courses">
              <DropdownMenuItem className="cursor-pointer text-base">My Courses</DropdownMenuItem>
            </Link>
          </SignedIn>
        </DropdownMenuContent>
      </DropdownMenu>
      <SignedIn>
        <UserButton />
      </SignedIn>
    </nav>
  );
}
